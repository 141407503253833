import { useEffect, useState } from "react";
import "../css/home.css";
import { setProgressBar } from "./login";
import { IdeasHead } from "./ideas";
import { useBetween } from "use-between";
import { axiosCreate } from "./restrict/restrict";
import unEscape from "../modules/unscape";
import "../files/images/avatar.jpg"
import Head from "./head";

var w = window;
var d = document;
var el = null;
var durationAnimation = 0;
var timerAnimation = false
var pos = {};
var gb = "getBoundingClientRect";

function up(e) {
   pos.end = pos.actual;
   clearInterval(timerAnimation);

   if (durationAnimation < 3 && pos.breakScroll > 100) {
      var velocity = pos.breakScroll / 1000;
      velocity = (2 * (.6 + velocity));
      e.el = el.firstElementChild;
      e.el.classList.add("smooth");
      pos.actualAn = (pos.actualAn * velocity) + (pos.end || 0);
      pos.actualAn = Math.max(-pos.block, pos.actualAn);
      pos.actualAn = Math.min(pos.max, pos.actualAn);

      w.requestAnimationFrame(function () {
         e.el.style.transform = `translate3d(${-pos.actualAn}px, 0px, 0px)`;
         var ps = Math.max(0, pos.actualAn / 50);
         pos.nex.style.transform = `translate3d(${-ps}px, 0px, 0px)`;
      });

      pos.end = pos.actualAn;
   }

   durationAnimation = 0;

   if (pos.end < 0) {
      e.el = el.firstElementChild;
      pos.end = 0;
      e.el.classList.add("smooth");
      w.requestAnimationFrame(function () {
         e.el.style.transform = `translate3d(0px, 0px, 0px)`;
      })
   };

   if (pos.actual + pos.block > pos.max && pos.direction) {
      e.el = el.firstElementChild;
      pos.end = pos.max;
      e.el.classList.add("smooth");
      w.requestAnimationFrame(function () {
         e.el.style.transform = `translate3d(-${pos.max}px, 0px, 0px)`;

      })
   };

   el.classList.remove("grab", "grabbing");
   el.onpointerup = false;
   el.onpointerleave = false;
   el.onpointermove = false;
}

function move(e) {
   el.classList.add("grabbing");
   e.el = el.firstElementChild;
   pos.block = 400;
   pos.move = e.clientX;
   pos.actual = pos.start - pos.move;
   pos.actualAn = pos.actual;
   pos.direction = pos.actual > 0;
   pos.breakScroll = Math.abs(pos.actual);
   if (pos.scrolling && pos.breakScroll < 20) return;

   pos.max = (e.el.firstElementChild[ gb ]().width + 30) * e.el.children.length;
   pos.max = pos.max - el[ gb ]().width - 30;

   pos.velocity = 1;

   pos.actual = (pos.actual * pos.velocity) + (pos.end || 0);
   pos.actual = Math.max(-pos.block, pos.actual);
   pos.actual = Math.min(pos.max + pos.block, pos.actual);

   w.requestAnimationFrame(function () {
      e.el.style.transform = `translate3d(${-pos.actual}px, 0px, 0px)`;
      var ps = Math.max(0, pos.actual / 50);
      pos.nex.style.transform = `translate3d(${-ps}px, 0px, 0px)`;
   });
}

function down(e) {
   if (e.shiftKey || w.innerWidth <= 1024) return;
   console.log(true);
   el = e.target.closest(".projects");
   e.el = el.firstElementChild;
   e.nex = el.nextElementSibling;
   pos.nex = e.nex.firstElementChild;

   if (e.el[ gb ]().width < el[ gb ]().width) return;

   pos.start = e.clientX;
   el.classList.add("grab");
   el.onpointerup = up;
   el.onpointerleave = up;
   el.onpointermove = move;

   e.el.classList.remove("smooth");

   timerAnimation = setInterval(() => {
      durationAnimation++;
   }, 100);
}

function moveSlide(a, e) {
   var r = d.getElementsByClassName("projects")[ 0 ];
   var parent = e.target.parentElement;
   r = r.firstElementChild;
   var f = r.firstElementChild[ gb ]().width;

   if (!pos.actual) pos.actual = 0;
   pos.end = (f + 30) * a;
   r.classList.add("smooth-btn");
   r.style.transform = `translate3d(${-pos.end}px, 0px, 0px)`;

   var s = parent.getElementsByClassName("selected")[ 0 ];
   s.className = "";
   e.target.className = "selected";

   parent.style.transform = `translate3d(-${24 * a}px, 0px, 0px)`;

   setTimeout(() => {
      r.classList.remove("smooth-btn");
   }, 1000);
}

function converterDate(date) {
   return new Date(date).toLocaleString("pt-BR", {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
   })
}

function logoProject(logo) {
   return `/files/projects/${logo}.svg`;
}

function CreateProject(params) {
   var { data } = params;
   if (typeof data === "number") {
      return (
         <div className="mask-content">
            <p>------------------- - - - - - ----</p>
            <a href="./" target="_blank" rel="noreferrer">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> </svg>
               <h3>------------------- - - - - - -------------</h3>
            </a>
            <p>------------------- - - - - - -------------------------------- - </p>
            <a href="./" target="_blank" rel="noreferrer">------------------- - - - </a>
            <p> --------------------------- - - - - - -------------------------------- - - - - - -------------------------------- - - - - - -------------------------------- - - - - - -------------------------------- - - - - - -------------------------------- - - - - - -------------------------------- - - - - - -------------------------------- - - - - - -------------</p>
            <a href="./" target="_blank" rel="noreferrer">
               <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10,-2.27373675e-13 C15.51,-2.27373675e-13 20,4.49 20,10 C20,15.52 15.51,20 10,20 L10,20 L9.72023508,19.9961558 C4.32942258,19.8478368 -2.26761352e-13,15.4264407 -2.26761352e-13,10 C-2.26761352e-13,4.49 4.48,-2.27373675e-13 10,-2.27373675e-13 Z M8.56,5.78 C8.36,5.78 8.17,5.85 8.02,6 C7.73,6.3 7.73,6.77 8.03,7.06 L8.03,7.06 L10.98,10 L8.03,12.94 C7.73,13.23 7.73,13.71 8.02,14 C8.32,14.3 8.79,14.3 9.08,14 L9.08,14 L12.57,10.53 C12.71,10.39 12.79,10.2 12.79,10 C12.79,9.8 12.71,9.61 12.57,9.47 L12.57,9.47 L9.08,6 C8.94,5.85 8.75,5.78 8.56,5.78 Z"></path>
               </svg>
            </a>
         </div>
      )
   };

   return (
      <div className="projects-actived"
         onMouseMove={e => {
            if (w.innerWidth < 1024) return;
            var light = e.currentTarget.firstElementChild;
            light.style.opacity = "1";
            light.style.transition = ".3s opacity"
            var left = e.clientX - e.currentTarget.getBoundingClientRect().left - 190;
            var top = e.clientY - e.currentTarget.getBoundingClientRect().top - 190;

            e.currentTarget.style.transition = "0s";
            light.style.transform = `matrix(1,0,0,1,${left / 1.5},${top / 1.5})`;
            e.currentTarget.style.transform = `matrix(1,${left / 10000},${top / 10000},1,0,0) perspective(200px)`;
         }}

         onMouseLeave={e => {
            if (w.innerWidth < 1024) return;
            e.currentTarget.style.transform = "matrix(1,0,0,1,0,0)";
            var light = e.currentTarget.firstElementChild;
            light.style.opacity = "0";
            light.style.transition = ".3s"
            e.currentTarget.style.transition = ".3s";
         }}
      >
         <span className="light"></span>
         <p>{data.release > new Date().getTime() ? <>Release</> : <>Update</>}: {converterDate(data.release)}</p>
         <a href={unEscape(data.url)} target="_blank" rel="noreferrer">
            {/*  <img src={logoProject(data.icon)} alt={data[ "project-name" ]} /> */}
            <figure></figure>
            <h3 className="main-theme">{data[ "project-name" ]}</h3>
         </a>
         <p>{data.title}</p>
         <a href={unEscape(data.url)} target="_blank" rel="noreferrer">Access project</a>
         <p title={data.description}>{data.description.length > 150 ? data.description.slice(0, 150) + "..." : data.description}</p>
         <a href={unEscape(data.url)} target="_blank" rel="noreferrer">
            <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
               <path d="M10,-2.27373675e-13 C15.51,-2.27373675e-13 20,4.49 20,10 C20,15.52 15.51,20 10,20 L10,20 L9.72023508,19.9961558 C4.32942258,19.8478368 -2.26761352e-13,15.4264407 -2.26761352e-13,10 C-2.26761352e-13,4.49 4.48,-2.27373675e-13 10,-2.27373675e-13 Z M8.56,5.78 C8.36,5.78 8.17,5.85 8.02,6 C7.73,6.3 7.73,6.77 8.03,7.06 L8.03,7.06 L10.98,10 L8.03,12.94 C7.73,13.23 7.73,13.71 8.02,14 C8.32,14.3 8.79,14.3 9.08,14 L9.08,14 L12.57,10.53 C12.71,10.39 12.79,10.2 12.79,10 C12.79,9.8 12.71,9.61 12.57,9.47 L12.57,9.47 L9.08,6 C8.94,5.85 8.75,5.78 8.56,5.78 Z"></path>
            </svg>
         </a>
      </div>
   )
}

var dataFailure = [
   {
      "_id": "9e9d285d20282c298b0b08ad710b7809",
      "release": 1732244400000,
      "project-name": "Maxx Pro Supplementos",
      "url": "https://maxxprosuplementos.com.br/",
      "title": "Seu destino para performance e estilo.",
      "description": "A Maxx Pro Suplementos é a loja perfeita para quem busca elevar seu treino! Com uma vasta seleção de suplementos de alta qualidade, roupas de academia confortáveis e estilosas, além de equipamentos para treinos em casa, eles têm tudo o que os clientes precisam para alcançar seus objetivos fitness."
   },
   {
      "_id": "9e9d285d20282c298b0b08ad710b7809",
      "release": 1729393200000,
      "project-name": "Sloop Burger",
      "url": "https://sloopburger.com/",
      "title": "A experiência do hambúrguer artesanal!",
      "description": "A Sloop Burger é o lugar ideal para os amantes de hambúrgueres artesanais! Com ingredientes frescos e de alta qualidade, cada hambúrguer é uma explosão de sabor, preparado com todo carinho e atenção aos detalhes. Além de opções clássicas, eles oferecem criações exclusivas que vão surpreender seu paladar."
   },
   {
      "_id": "5t23f3ed20282c298b0b08ad710b7809",
      "release": 1703127600000,
      "project-name": "Dark Translate",
      isCredential: true,
      "url": "https://darktranslate.chrisaxxwell.com/",
      "title": "The incredible page translator.",
      "description": "Dark Translate empowers Developers and users to translate their web pages or applications easily and quickly, eliminating the need for advanced knowledge. In addition, its versatility allows translation into any desired language, with more than 144 languages ​​available."
   },
   {
      "_id": "aea4cac5055dfc77947138e8f59a1405",
      "release": 1719889200000,
      "project-name": "MerlinDB (Database)",
      "url": "https://merlindb.chrisaxxwell.com/",
      "title": "The indexedDB API database based on MongoDB.",
      "description": "MerlinDB is an IndexedDB-based database designed to store data locally in web browsers. Unlike conventional databases like MongoDB, MerlinDB is optimized for web applications, providing an efficient and lightweight solution for storing data at browser runtime."
   },
   {
      "_id": "0e40afcc59cef327c859cf1d26f55bb4",
      "release": 1721617200000,
      "project-name": "Crypthor",
      "url": "https://crypthor.chrisaxxwell.com/",
      "title": "Encrypting messages for sharing.",
      "description": "The Message Encryption Tool is an innovative solution developed to ensure the security and privacy of digital communications. Using state-of-the-art encryption algorithms, the tool transforms messages into indecipherable codes, ensuring that only authorized recipients can access them."
   },
   {
      "_id": "0abb590d530465700a090566e48b3b4e",
      "release": 1712890800000,
      "project-name": "Apple News+",
      "url": "https://www.apple.com/apple-news/",
      "title": "A world of journalism. One trusted subscription.",
      "description": "Apple News+ is a news subscription service from Apple, launched in March 2019. It offers access to hundreds of magazines, newspapers, and digital publications, all in one app. The service includes content from renowned sources such as The Wall Street Journal, Los Angeles Times, National Geographic, and many more, allowing subscribers to read full articles, back issues, and enjoy interactive layouts."
   },
   {
      "_id": "623d1bd1b96b8357a690c73ff504edad",
      "release": 1652756400000,
      "project-name": "Excalidraw",
      "url": "https://excalidraw.com/",
      "title": "Hand-drawn look and feel.",
      "description": "Excalidraw is an online real-time drawing and collaboration tool that lets you create diagrams, sketches, and annotations intuitively. It is known for its simplicity and user-friendly interface, allowing users to create freehand drawings, add shapes, text, and connect ideas visually."
   },
   {
      "_id": "1ed49763b8e2be01e59c0a94235be7d3",
      "release": 1673146800000,
      "project-name": "ILoveIMG",
      "url": "https://www.iloveimg.com/",
      "title": "Online tools for editing images.",
      "description": "iLoveIMG is an online tool that allows you to edit images quickly and easily. With it, you can compress, resize, crop, convert formats (such as JPG to PNG), and even create memes. The platform is intuitive and offers several editing options directly in the browser, without the need to install additional software. It is useful for users who need to perform simple and efficient edits to their images."
   },
   {
      "_id": "0a533d2847aabb3ba0fe7e8c1ebde898",
      "release": 1689130800000,
      "project-name": "IINA (Media player)",
      "url": "https://iina.io/",
      "title": "The modern media player for macOS.",
      "description": "iina.io is an advanced media player for macOS, based on the powerful mpv player. It offers a modern and user-friendly interface, support for a wide range of audio and video formats, and features such as playback control, customizable subtitles, and integration with the Touch Bar on MacBooks. It is a popular alternative to VLC for macOS users due to its intuitive design and robust functionality."
   },
];

async function getData() {
   var get = await axiosCreate.get("/projects/get").catch(e => {
      return false;
   });

   return get.data;
}

var useProjects = function () {
   var [ projects, setProjects ] = useState([]);
   var [ mask, clearMask ] = useState([ 1, 2, 3, 4, 5, 6, 7 ]);

   useEffect(() => {
      //Provisorio

      setProjects(dataFailure);
      clearMask([])

      /*   getData().then(e => {
  
           if (!e || e.data.length === 0) {
              e = {};
              e.data = dataFailure;
           };
  
           setProjects(e.data);
           clearMask([])
        }); */
   }, []);

   return { projects, mask };
}

var useShareProjects = () => useBetween(useProjects);

export default function Home() {
   var { projects, mask } = useShareProjects();

   useEffect(function () {
      setProgressBar();
      w.scrollTo(0, 0);
   }, []);

   return (
      <main id="home">
         <Head props={{
            site_name: "Chris Axxwell",
            page_type: "website",
            title: "Chris Axxwell - Software Developer | Crafting Innovative Applications",
            description: "Skilled software developer building custom applications from scratch with React, Node.js, Swift, NoSQL, C, and C++. Dedicated to creating high-quality, innovative solutions.",
            page_author: "Chris Axxwell",
            index: "index, follow",
            keywords: "software developer, software enginner, developer, desenvolvedor, applications, website, mobile, React, Node.js, Swift, NoSQL, C, C++, application development, custom software solutions, innovative technology",
            canonical: "/",
            lang: "en_US",
            theme_color: "#040404",
            img_page: "/chrisaxxwell-poster.jpg",
            img_alt: "Chris Axxwell Poster",
            twitter_username_creator: "chrisaxxwell",
            twitter_username: "chrisaxxwell",
            icon: "/logo.svg",
            noCache: false, //adicone true, em paginas de usuario e paginas que nao quer adiionar no cache, se for true entao noa adicionapaginas no cache tipo para paginas de usuario, paginas de blogs atualizados e tals
            //redirect: ["https://www.chrisaxxwell.com", 3], //redireciona a pagina para outra pagina ou outro dominio depois de 3 segundos ou nao defina para 0
         }} />

         <section id="intro" className="intro-animation">
            <p className="main-theme escrita notranslate" translate="no">chris axxwell.</p>
            <h1 className="white-linear">You have the power to change the world.</h1>

            {/* PROJECTS */}
            <section className="projects" onMouseDown={down}>
               <div>
                  {
                     mask ?
                        mask.map((e, i) => (
                           <CreateProject data={e} key={i} />
                        )) : null
                  }
                  {projects.map((e, i) => (
                     <CreateProject data={e} key={i} />
                  ))}
               </div>
            </section>

            <div className="index-car">
               <div>
                  {projects.length + ": "}
                  {
                     projects.map((e, i) => (
                        <span key={i} className={i === 0 ? "selected" : null} onPointerDown={moveSlide.bind(this, i)}></span>
                     ))
                  }
               </div>
            </div>
         </section>
         <IdeasHead home="valid" />
      </main>
   )
}

export { useShareProjects, converterDate };